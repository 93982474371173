import { render, staticRenderFns } from "./HeroWithForm.vue?vue&type=template&id=44833174&scoped=true"
import script from "./HeroWithForm.js?vue&type=script&lang=js&external"
export * from "./HeroWithForm.js?vue&type=script&lang=js&external"
import style0 from "./HeroWithForm.scss?vue&type=style&index=0&id=44833174&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44833174",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTag: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Tag/Tag.vue').default,HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default,HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default,HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})
