export default {
  props: {
    tag: {
      type: String,
    },
    title: {
      type: Object,
    },
    body: {
      type: String,
    },
    image: {
      type: Object,
    },
    info: {
      type: String,
    },
    button: {
      type: Object,
    },
  },

  computed: {
    hasTag() {
      return this.tag ? true : false
    },
    hasTitle() {
      return this.title ? true : false
    },
    hasBody() {
      return this.body ? true : false
    },
    hasInfo() {
      return this.info ? true : false
    },
    hasImage() {
      return this.image ? true : false
    },
    hasButton() {
      return this.button ? true : false
    },
  },
}
